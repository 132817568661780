// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-covid-js": () => import("./../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-cursos-js": () => import("./../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */)
}

